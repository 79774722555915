const casestudy__slide = new Splide(".case-study__slide", {
  type: "loop",
  pagination: false,
});

const projects1__slide = new Splide(".projects1", {
  type: "loop",

  pagination: false,
});

const projects2__slide = new Splide(".projects2", {
  type: "loop",
  pagination: false,
});

const projects3__slide = new Splide(".projects3", {
  type: "loop",
  pagination: false,
});

casestudy__slide.mount();
projects1__slide.mount();
projects2__slide.mount();
projects3__slide.mount();
